import React from "react";
import Layout from "../../components/layout";
import { Container, H1 } from "../../styles/ui";
import styled from "@emotion/styled";
import Contact from "../../components/contact";
import IgyDolgozunk1 from "../../images/svg/igydolgozunk1.svg";
import IgyDolgozunk2 from "../../images/svg/igydolgozunk2.svg";
import IgyDolgozunk3 from "../../images/svg/igydolgozunk3.svg";
import IgyDolgozunk4 from "../../images/svg/igydolgozunk4.svg";
import IgyDolgozunk5 from "../../images/svg/igydolgozunk5.svg";
import IgyDolgozunk6 from "../../images/svg/igydolgozunk6.svg";

const IgyDolgozunk: React.FC = () => {
  return (
    <Layout
      allowTransparentHeader={true}
      variant="weboldal"
      title="Így dolgozunk"
      description="Weboldal munkafolyamatok leírása"
    >
      <Wrapper>
        <H1>
          Így <span>dolgozunk</span>
        </H1>
        <Grid>
          <Step>
            <Number>
              <h1>1</h1>
            </Number>
            <IgyDolgozunk1 />
            <Title>
              <h3>Igények felmérése</h3>
            </Title>
            <p>
              Első lépésként egy online meeting keretein belül tisztázzuk az
              igényeket, a szükséges funkciókat és azok megvalósításának
              lehetőségeit. Begyűjtjük a szöveges és képi tartalmakat, majd
              minezek ismeretében megkezdjük a tervezést.
            </p>
          </Step>
          <Step>
            <Number>
              <h1>2</h1>
            </Number>
            <IgyDolgozunk2 />
            <Title>
              <h3>Drótváz kialakítása</h3>
            </Title>
            <p>
              Az igények és a tartalom ismeretében elkészítjük a szükséges
              oldalak drótvázát, asztali és mobil nézetben. Ez egy egyszerű
              terv, amely csupán a képek és a tartalmak helyét, az űrlapok
              felépítését és a különböző tartalmi elemek egymáshoz való
              viszonyát hivatott megjeleníteni. A drótváz elkészültekor egy
              újabb meeting keretein belül átbeszéljük, hogy minden a megfelelő
              helyen / oldalon jelenik-e meg vagy van esetleg szükséges
              valamilyen módosítás.
            </p>
          </Step>
          <Step>
            <Number>
              <h1>3</h1>
            </Number>
            <IgyDolgozunk3 />
            <Title>
              <h3>Design megtervezése</h3>
            </Title>
            <p>
              Az elfogadott drótvázak alapján kialakításra kerül a design. Ez
              már tartalmazza a brand színeit, a betűtípusokat, képeket és
              minden grafikai formázást, ami életre kelti a drótvázban
              tisztázott tartalmakat.{" "}
            </p>
          </Step>
          <Step>
            <Number>
              <h1>4</h1>
            </Number>
            <IgyDolgozunk4 />
            <Title>
              <h3>Fejlesztés</h3>
            </Title>
            <p>
              Az elfogadott designtervek alapján felépítjük a weboldalt az előre
              tisztázott alapokon. Emellett felöltjük a tartalmakat és
              előkészítjük az oldalt az átadásra.
            </p>
          </Step>
          <Step>
            <Number>
              <h1>5</h1>
            </Number>
            <IgyDolgozunk5 />
            <Title>
              <h3>Átadás</h3>
            </Title>
            <p>
              Elmagyarázzuk az elkészült weboldal működését és segítünk
              elindítani azt, hogy saját magad is tudj tartalmat generálni rá.
            </p>
          </Step>
          <Step>
            <Number>
              <h1>6</h1>
            </Number>
            <IgyDolgozunk6 />
            <Title>
              <h3>Üzemeltetés és támogatás</h3>
            </Title>
            <p>
              Ha nem szeretnél foglalkozni a weboldalad naprakészen tartásával,
              vagy időközben merül fel problémád vagy új igényed, frodulj
              hozzánk bizalommal. Vállaljuk weboldalak üzemeltetését,
              karbantartását és akár bővítését.
            </p>
          </Step>
        </Grid>
      </Wrapper>
      <Contact variant="weboldal" />
    </Layout>
  );
};

export default IgyDolgozunk;

const Wrapper = styled(Container)`
  color: white;
  padding-top: 160px;
  padding-bottom: 96px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 64px;
  gap: 32px;
  @media (max-width: 440px) {
    grid-template-columns: 1fr;
  }
`;

const Step = styled.div`
  overflow: hidden;
  position: relative;
  padding: 32px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: linear-gradient(180deg, #212225 0%, #292a2c 100%);

  svg {
    width: 60px;
    height: 60px;
    margin-bottom: 16px;
  }
`;

const Number = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background: #767676;
  transform: translate(20%, -20%);
  display: grid;
  place-items: center;

  h1 {
    margin: 0;
    color: #212225;
    transform: translate(-8px, 8px);
  }
`;

const Title = styled.div`
  position: relative;
  margin: 32px 0;

  h3 {
    display: inline-block;
    position: relative;

    &:after {
      content: "";
      display: block;
      height: 4px;
      background: var(--color-primary);
      width: auto;
    }
  }
`;
